import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
 en:{
   cancel: 'Cancel',
   cancelbutton: 'Back to home screen',
   continue: 'Continue',
   next: 'Next',
   back: 'Back',
   yes: 'Yes',
   no: 'No',
   start_over: 'Start over',
   submit: 'Submit',
   or: 'or',
   thankyou : 'Thank you!',
   please_select: 'Please select',
   error_1: '🙁 We are sorry, we have encountered an error in the app.',
   error_2: 'Please try again or contact us with details about how this happened.',

   letsgo: 'Ok, start',

   cf_intro_heading: 'Hi! We can assist you with any hair related questions!',
   cf_intro_text: 'Answer 3 questions. Then the salon will reply to you via email...',

   cf_selfie_heading: 'An image of your hair today',
   cf_selfie_text: 'Often we need an image to give you the best advice. Send an image that shows your hair today but you can also continue without.',
   cf_skipselfie_button: 'Continue without image',
   cf_choose_image: 'Choose an image',
   cf_removeselfie_button: 'Remove image',
   cf_error_not_an_image: 'The file is not an image',

   cf_message_heading: 'Write your question here',
   cf_message_text: '(For example: if you are looking for a new hairstyle, hair advice or if you have some product or treatment question)',

   cf_inspoimage_heading: 'Do you have some other image that describes what you are interested in?',
   cf_inspoimage_text: 'For example: An image of a hair model or celebrity that you like',
   cf_choose_inspo_image: 'Upload an inspiration image',
   cf_skipsinspoimage_button: 'Continue without image',
   cf_inspo_confirm_heading: 'Upload an image showing the hairstyle you want to test',
   cf_inspo_confirm_text: 'Lorem ipsum lorem ipsum',
   cf_removesinspoimage_button: 'Remove image',

   cf_email_heading: 'Last step: Type your email address',

   cf_thanks_heading: 'Thank you!',
   cf_thanks_text: 'The salon will get back to you soon!',

   search_heading: 'Find a hairdresser',
   search_city_placeholder: 'Enter the name of your city',

   city_header_prefix: 'Hairdressers in',
   city_no_hits: 'No salons are found for the selected service. We are bringing in new salons and hairdressers to the app continuously. Please search again soon...',
   play: 'Play',

   get_consultation: 'Get consultation',

   website: 'Website',


 },
 sv: {
   cancel: 'Avbryt',
   cancelbutton: 'Gå till hemskärmen',
   continue: 'Fortsätt',
   next: 'Nästa',
   back: 'Bakåt',
   yes: 'Ja',
   no: 'Nej',
   start_over: 'Börja om',
   submit: 'Skicka',
   or: 'eller',
   thankyou : 'Tack!',
   please_select: 'Vänligen välj',
   error_1: '🙁 Sorry, det har blivit något fel i appen.',
   error_2: 'Prova gärna igen eller kontakta oss med info om hur detta hände.',

   letsgo: 'Ok, kör igång',


   cf_intro_heading: 'Hej! Vi hjälper dig med din fråga eller fundering',
   cf_intro_text: 'Svara på 3 frågor. Sedan svarar salongen dig via e-post...',

   cf_selfie_heading: 'En bild på ditt hår idag',
   cf_selfie_text: 'Ofta behöver vi en bild för att ge dig bra råd. Skicka en bild som visar ditt hår idag men det går också bra att fortsätta utan.',
   cf_skipselfie_button: 'Fortsätt utan bild',
   cf_choose_image: 'välj en bild',
   cf_removeselfie_button: 'Ta bort bild',
   cf_error_not_an_image: 'Filen är inte en bild',

   cf_message_heading: 'Skriv din fråga eller fundering här',
   cf_message_text: '(T.ex. om du är ute efter ny frisyr, vill ha råd eller undrar om produkter och behandlingar)',

   cf_inspoimage_heading: 'Har du någon annan bild som beskriver vad du är intresserad av?',
   cf_inspoimage_text: 'T.ex. Bild på en hårmodell eller en kändisfrisyr som du gillar',
   cf_choose_inspo_image: 'Ladda upp inspirations bild',
   cf_skipsinspoimage_button: 'Fortsätt utan bild',
   cf_inspo_confirm_heading: 'Ladda upp en bild som visar en frisyr som du skulle vilja testa',
   cf_inspo_confirm_text: 'Lorem ipsum lorem ipsum',
   cf_removesinspoimage_button: 'Ta bort bild',

   cf_email_heading: 'Sista steget: Ange din e-postadress',

   cf_thanks_heading: 'Tack!',
   cf_thanks_text: 'Salongen återkommer till dig snart!',

   search_heading: 'Hitta frisör',
   search_city_placeholder: 'Skriv in namnet på din stad',

   city_header_prefix: 'Frisörer i',
   city_no_hits: 'Inga salonger hittades för valda behandlingar. Nya salonger och frisörer ansluter sig kontinuerligt till Min Frisör. Var god prova igen snart...',
   play: 'Spela',

   get_consultation: 'Få konsultation',

   website: 'Webbplats',


 }
});

export default strings;