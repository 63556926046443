import React from 'react'

import { css } from "@emotion/react";
import GridLoader from "react-spinners/GridLoader";

class Loader extends React.Component {

    
    render(){
        
        // Can be a string as well. Need to ensure each key-value pair ends with ;

        const override = css`
            display: block;
            margin: 0 auto;
        `;

        let color = "#4B36CC";

        const loading = true;

        return(

            <GridLoader color={color} loading={loading} css={override} size={12} margin={18} />

        )

    }
   
}

export default Loader