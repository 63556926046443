import React from 'react'
import strings from '../language/strings';
import { useNavigate } from "react-router-dom";
import chevron_left from '../assets/img/chevron_left.svg';

function BackLink(props) {

    const navigate = useNavigate();

    const goBack = () => {
        //navigate(-1);
        navigate('/');
    }

       return(
        <button className="navbutton navbutton--left" type="button" onClick={goBack}>
            <img src={chevron_left} alt={strings.back} width="12" height="21" /><span>{strings.back}</span>
        </button>
       )

   
}

export default BackLink