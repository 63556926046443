import React from 'react'
import strings from '../language/strings';
import { useNavigate } from "react-router-dom";
import chevron_left from '../assets/img/chevron_left.svg';

function BackToCityLink(props) {

    const navigate = useNavigate();

    const goBack = () => {
        
        if( window.last_city ){
            navigate( '/city/' + window.last_city );
        }
        else{
            navigate('/');
        }
        



    }

       return(
        <button className="navbutton navbutton--left" type="button" onClick={goBack}>
            <img src={chevron_left} alt={strings.back} width="12" height="21" /><span>{strings.back}</span>
        </button>
       )

   
}

export default BackToCityLink