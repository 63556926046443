import React from 'react'
import strings from '../language/strings';

import { Link } from "react-router-dom";

class CancelButton extends React.Component {

    render(){

       if( this.props.route && this.props.route !== '' ){
            return(
                
                <Link className="button button--black" to={`${this.props.route }`}>{ strings.cancelbutton }</Link>
            
            )
       }
       else{
           return '';
       }

    }
   
}

export default CancelButton