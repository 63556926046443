import React, { useRef } from 'react'
import strings from '../language/strings';

import svg_play from '../assets/img/play.svg';



//strings.setLanguage('sv');


  

function CompanyMediaAlt(props) {

    const companyData = props.companyData;

    const videoRef = useRef();
    const posterRef = useRef();


    const PlayVideo = e => {
        e.preventDefault();
        posterRef.current.style.display = 'none';
        videoRef.current.style.display = '';
        videoRef.current.play();

    }


    if( companyData.videoUrl ){

      

        return(
            <div className="company-video-alt">
                <video width="100%" height="auto" preload="auto" style={{ display : 'none' }} ref={videoRef} controls>
                    <source src={companyData.videoUrl} type="video/mp4" />
                </video>
                <div className="company-video__poster" ref={posterRef}  onClick={ e => PlayVideo(e) } >
                    {companyData.thumbnailUrl ? (
                        <img src={companyData.thumbnailUrl} alt="Poster" className="" />
                    ) : (
                        <></>
                    )}
                    <img src={svg_play} alt={strings.play} className="playbutton" width="96" height="96" />
                </div>
            </div>
            
        )


        
    }
    else if( companyData.pictureName ){
        return(
            <div className="company-image">
              <img src={companyData.pictureName} alt={companyData.companyName} />
            </div>
            
        )
    }
    else{
        return(<></>)
    }
 
    
        



   
}

export default CompanyMediaAlt