import React, { useState, useEffect } from 'react'
import strings from '../language/strings';

import api from "../functions/api";

import { Link } from "react-router-dom";

import svg_search from '../assets/img/search.svg';


//strings.setLanguage('sv');

function Search(props) {

    const [cities, setCities] = useState([
        { value: 'Malmö', label: 'Malmö', lowercase: 'malmo' },
        { value: 'Stockholm', label: 'Stockholm', lowercase: 'stockholm' },
    ]);

    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        // Code here will run just like componentDidMount

        Promise.all([api.getAllCities()])
        .then(([citiesData]) => {

            const options = [];

            citiesData.forEach(function(item){

                options.push( { value: item.city, label: item.city, lowercase: item.city.toLowerCase() } );

            });

            setCities( options );

        });


      }, []);



    const updateSuggestions = e => {
        e.preventDefault();

        let suggestions = [];

        if( e.target.value.length > 1 ){
            suggestions = cities.filter(city => {
                return city.lowercase.includes( e.target.value.toLowerCase() );
            });
        }

      

        setSuggestions( suggestions );

    }



    return(
        <div className="app-inner consultation-form">

            <div className="app-top">

                {window.returnUrl ? (
                    <div className="cancel-content">
                        <a href={window.returnUrl} className="">{ strings.cancel }</a>
                    </div>
        
                ) : (
                    <></>
                )}
              
    

                <h2>{strings.search_heading}</h2>
                <input style={{ backgroundImage:`url(${svg_search})` }} type="text" className="city-input" placeholder={strings.search_city_placeholder} onChange={ updateSuggestions } />
                <div className="city-suggestions">
                    {suggestions.length ? (
                        <ul>
                        {suggestions.map((suggestion) => (
                            <li key={suggestion.value}>
                            <Link to={`city/${suggestion.label}`}>
                                {suggestion.label}
                            </Link>
                            </li>
                        ))}
                        </ul>
                    ) : (
                        <></>
                    )}
                </div>

            </div>

            

            

        </div>
    )
        



   
}

export default Search