import React from 'react';
import validator from 'validator';
import strings from '../language/strings';
import { LOCAL_API_BASE } from "../utils/constants";
import CancelLink from '../components/CancelLink';
//import CancelButton from '../components/CancelButton';
import Loader from '../components/Loader';
import { useNavigate, useParams } from 'react-router-dom';

export function withRouter(Children){
    return(props)=>{

        const navigate = useNavigate();
        const match  = {params: useParams()};
        return <Children {...props}  match = {match} navigate={navigate} />

   }
 }

class ConsultationForm extends React.Component {

    constructor(props) {
        super(props);

        let { companyId } = this.props.match.params;
        console.log(companyId);

        this.selfieInputRef = React.createRef();
        this.inspoimageInputRef = React.createRef();

        this.state = { 
            formstep: 'intro',
            error_message : false,
            companyId : companyId,
            error_email : false,
            loading: false,
            message: '',
            selfiefile: '',
            selfieobject: '',
            selfie: false,
            inspoimagefile: '',
            inspoimageobject: '',
            inspoimage: false,
            email: '',
        }

        this.handleInputChange = this.handleInputChange.bind(this);

    }


    HandleSelfieChange = e => {
        e.preventDefault();

        if (e.target.files[0].type && e.target.files[0].type.indexOf('image') === -1) {
            alert( strings.cf_error_not_an_image );
            return;
        }

        let selfieobject = (URL.createObjectURL(e.target.files[0]));

        this.setState( {
            selfiefile: e.target.files[0], 
            selfieobject: selfieobject, 
            selfie: true, 
            formstep: 'selfieconfirm' 
        } );

    }

    HandleInspoImageChange = e => {
        e.preventDefault();

        if (e.target.files[0].type && e.target.files[0].type.indexOf('image') === -1) {
            alert( strings.cf_error_not_an_image );
            return;
        }

        let inspoimageobject = (URL.createObjectURL(e.target.files[0]));

        this.setState( {
            inspoimagefile: e.target.files[0], 
            inspoimageobject: inspoimageobject, 
            inspoimage: true, 
            formstep: 'inspoconfirm' 
        } );

    }

    RemoveSelfie = e => {
        e.preventDefault();
        this.setState( { formstep: 'selfie', inspoimage: false, inspoimageobject: '' } );
    }

    RemoveInspoImage = e => {
        e.preventDefault();
        this.setState( { formstep: 'inspoimage', selfie: false, selfieobject: '' } );
    }

    SwitchStep( e, step ){
        e.preventDefault();
        this.setState( { formstep: step } );
        
    }

    TriggerInput( e, ref ){

        e.preventDefault();

        ref.current.click();

    }

    handleInputChange( e ) {

        const target = e.target;

        let value = target.value;

        const name = target.name;

        this.setState({

            [name]: value

        });
        
    }

    SubmitForm( e ){
        e.preventDefault();

        if (validator.isEmail( this.state.email ) ) {
            
            this.send_data();
        }
        else{
            console.log('Email error');
            this.setState( { error_email : true } );
        }


    }

    send_data(){

        this.setState({ loading : true });

        const api_url = LOCAL_API_BASE + '/consultation.php';

        let data = this.state;
        data.selfieobject = ''; // we don't need to send this large chunk..
        data.inspoimageobject = ''; // we don't need to send this large chunk..

        let formdata = new FormData()

        formdata.append('selfie', this.state.selfiefile );
        formdata.append('inspoimage', this.state.inspoimagefile );
        formdata.append('message', this.state.message );
        formdata.append('language',strings.getLanguage() );
        formdata.append('email', this.state.email );
        formdata.append('email', this.state.email );
        formdata.append('companyId', this.state.companyId );

        let requestOptions = {
            method: 'POST',
            body: formdata,
        };

        fetch(api_url, requestOptions)
        .then(response => response.json())
        .then(data => {

            console.log(data);
          
            
            if( data.status ){
                this.setState({ formstep : 'thankyou', loading : false });
            }
            

        })
        .catch((error) => {
            console.error('Error:', error);

            this.props.navigate('/error');
    
        });

    }
    
    render(){

        const { formstep } = this.state;

        if( this.state.loading ){
            return(
             <div className="app-inner">
                    <Loader />
             </div>
            )
        }
        else{

            switch(formstep) {
                case 'intro': 
                return (
                    <div className="app-inner consultation-form">
                    
                        <div className="app-top">

                            <CancelLink route={`/hairdresser/${this.state.companyId}`} />

                            <h2>{strings.cf_intro_heading}</h2>

                            <p>{strings.cf_intro_text}</p>

                        </div>

                        <div className="app-bottom">

                            <button className="button button--submit" onClick={ e => this.SwitchStep(e, 'selfie') }>{strings.letsgo}</button>

                        </div>
                    </div>
                )
                case 'selfie': 
                return (
                    <div className="app-inner consultation-form">
                    
                        <div className="app-top">

                            <CancelLink route={`/hairdresser/${this.state.companyId}`} />

                            <h2>{strings.cf_selfie_heading} 📸</h2>

                            <p>{strings.cf_selfie_text}</p>


                                <div className="option-form">

                                    <input type="file" style={{ "display": "none" }} ref={this.selfieInputRef} onChange={ this.HandleSelfieChange } placeholder="{ strings.cf_choose_image }" />

                                    <button className="button button--black"
                                    onClick={ e => this.TriggerInput( e, this.selfieInputRef ) }
                                    >
                                    {strings.cf_choose_image}
                                    </button>

                                    <button className="button button--transparent"
                                    onClick={ e => this.SwitchStep(e, 'message') }
                                    >
                                    {strings.cf_skipselfie_button}
                                    </button>

                                </div>

                            </div>

                    </div>
                )
                case 'selfieconfirm': 
                return (
                    <div className="app-inner consultation-form">
                    
                        <div className="app-top">

                            <CancelLink route={`/hairdresser/${this.state.companyId}`} />

                            <h2>{strings.cf_selfie_heading} 📸</h2>

                            <p>{strings.cf_selfie_text}</p>


                                <div className="option-form">

                                    <p style={{ textAlign: 'center' }}><img style={{ maxWidth: '175px' }} alt="Selfie" src={ this.state.selfieobject } /></p>


                                    <button className="button button--transparent"
                                    onClick={ this.RemoveSelfie }
                                    >
                                    {strings.cf_removeselfie_button}
                                    </button>

                                </div>

                            </div>

                            <div className="app-bottom">
                                <button className="button button--submit" onClick={ e => this.SwitchStep( e, 'message' ) }>{strings.next}</button>
                            </div>

                    </div>
                )
                case 'message': 
                return (
                <div className="app-inner consultation-form">
                
                    <div className="app-top">

                        <CancelLink route={`/hairdresser/${this.state.companyId}`} />

                        <h2>{strings.cf_message_heading} 📸</h2>

                        <p>{strings.cf_message_text}</p>


                            <div className="option-form">

                                <textarea className="textarea" name="message" onChange={this.handleInputChange}></textarea>

                            </div>

                        </div>

                    <div className="app-bottom">

                        <button className="button button--submit" onClick={ e => this.SwitchStep( e, 'inspoimage' ) }>{strings.next}</button>

                    </div>
                </div>
            )
            case 'inspoimage': 
            return (
                <div className="app-inner consultation-form">
                
                    <div className="app-top">

                        <CancelLink route={`/hairdresser/${this.state.companyId}`} />

                        <h2>{strings.cf_inspoimage_heading} 📸</h2>

                        <p>{strings.cf_inspoimage_text}</p>


                            <div className="option-form">

                                <input type="file" style={{ "display": "none" }} ref={this.inspoimageInputRef} onChange={ this.HandleInspoImageChange } placeholder="{ strings.cf_choose_image }" />

                                <button className="button button--black"
                                onClick={ e => this.TriggerInput( e, this.inspoimageInputRef ) }
                                >
                                {strings.cf_choose_inspo_image}
                                </button>

                                <button className="button button--transparent"
                                onClick={ e => this.SwitchStep( e, 'email') }
                                >
                                {strings.cf_skipsinspoimage_button}
                                </button>

                            </div>

                        </div>

                </div>
            )
            case 'inspoconfirm': 
                return (
                    <div className="app-inner consultation-form">
                    
                        <div className="app-top">

                            <CancelLink route={`/hairdresser/${this.state.companyId}`} />

                            <h2>{strings.cf_inspo_confirm_heading} 📸</h2>

                            <p>{strings.cf_inspo_confirm_text} 👇</p>


                                <div className="option-form">

                                    <p style={{ textAlign: 'center' }}><img style={{ maxWidth: '175px' }} alt="Inspiration" src={ this.state.inspoimageobject } /></p>


                                    <button className="button button--transparent"
                                    onClick={ this.RemoveInspoImage }
                                    >
                                    {strings.cf_removesinspoimage_button}
                                    </button>

                                </div>

                            </div>

                            <div className="app-bottom">
                                <button className="button button--submit" onClick={ e => this.SwitchStep( e, 'email' ) }>{strings.next}</button>
                            </div>

                    </div>
                )
                case 'email': 
                return (
                <div className="app-inner consultation-form">
                
                    <div className="app-top">

                        <CancelLink route={`/hairdresser/${this.state.companyId}`} />

                        <h2>{strings.cf_email_heading} 📸</h2>


                            <div className="option-form">

                                <input type="email" name="email" className={this.state.error_email ? 'error' : ''} onChange={this.handleInputChange} />

                            </div>

                        </div>

                    <div className="app-bottom">

                        <button className="button button--submit" onClick={ e => this.SubmitForm( e ) }>{strings.submit}</button>

                    </div>
                </div>
            )
            case 'thankyou': 
                return (
                <div className="app-inner consultation-form">
                
                    <div className="app-top">


                        <h2>{strings.cf_thanks_heading} 📸</h2>

                        <p>{strings.cf_thanks_text} 👇</p>

                    </div>

                    { window.returnUrl ? (
                        <div className="app-bottom">
                            <a href={ window.returnUrl } className="button button--black">{ strings.cancelbutton }</a>
                        </div>
            
                    ) : (
                        <></>
                    )}

                </div>
            )
           default: 
                return (
                <></>
            )
            }
        }

  

    }
   
}

export default withRouter(ConsultationForm);