import 'reset-css';
import './App.css';

import React, { useState, useEffect } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/Layout';
import Search from './pages/Search';
import City from './pages/City';
import Company from './pages/Company';
import Error from './pages/Error';
import ConsultationForm from './pages/ConsultationForm';
import HairDresser from './pages/HairDresser';
import { return_url_man, return_url_woman } from "./utils/constants";

function App() {

  const [paramsChecked, setParamsChecked] = useState(false);

  
  useEffect(() => {
    // Code here will run just like componentDidMount

    if( !paramsChecked ){
      
      const queryParams = new URLSearchParams(window.location.search);

      const gender = queryParams.get('gender');

      if( gender ){

        if( gender === 'woman' ){
          window.returnUrl = return_url_woman;
        }
        else{
          window.returnUrl = return_url_man;
        }

      }

      setParamsChecked(true);

      //window.history.pushState(null, "", window.location.href.split("?")[0]);

      
    }

  }, [paramsChecked]);

    

  return (
    <div className="app">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Search />}/>
          <Route path="city/:cityName" element={<City />} />
          <Route path="company/:companyId" element={<Company />} />
          <Route path="hairdresser/:companyId" element={<HairDresser />} />
          <Route path="error" element={<Error />} />
          <Route path="consultation/:companyId" element={<ConsultationForm />} />
          <Route path="*" element={<Search />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
