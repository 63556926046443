import React from 'react'
import strings from '../language/strings';
import CancelLink from '../components/CancelLink';
import CancelButton from '../components/CancelButton';

class Error extends React.Component {

    
    render(){


       return(
        <div className="app-inner">

            <div className="app-top">

            <CancelLink route="/" />

                <h2>{strings.error_1}</h2>
                <h2>{strings.error_2}</h2>
            </div>
            <div className="app-bottom">
                <CancelButton route="/" />
            </div>

 
        </div>
        )


    }
   
}

export default Error