import React, { useState, useEffect } from 'react'
import strings from '../language/strings';

import api from "../functions/api";

import { useParams, useNavigate } from "react-router-dom";

import Loader from '../components/Loader';
import CompanyMediaAlt from '../components/CompanyMediaAlt';


import icon_email from '../assets/img/icon_email.svg';
import icon_web from '../assets/img/icon_web.svg';
import icon_phone from '../assets/img/icon_phone.svg';
import icon_directions from '../assets/img/icon_directions.svg';



function Company() {

    const [companyData, setCompanyData] = useState({});
    const [loading, setLoading] = useState (true); 


    const navigate = useNavigate();

    let { companyId } = useParams();


    useEffect(() => {
        // Code here will run just like componentDidMount

        Promise.all([api.getCompany( companyId )])
        .then(([response]) => {

            if( response ){


                console.log(response);            
            
                setCompanyData(response);
                setLoading(false);

            }
            else{
                navigate('/error');
            }
    


        });

      }, [companyId, navigate]);

    



    if( loading ){
        return(
         <div className="app-inner">
                <Loader />
         </div>
        )
    }
    else{
   
        
        return(
            <div className="app-inner consultation-form">
                    
                <div className="app-top">


                    

                    <CompanyMediaAlt companyData={companyData} />

                    

                    <h1 style={{ textAlign: 'left', marginTop: '15px' }}>{companyData.companyName}</h1>



                    <div className="company-details">

                        


                        <div className="company-details__row">

                            <img src={icon_phone} alt="icon" width="48" height="48" />
                            <span style={{ cursor : 'pointer' }} onClick={() => { 
                                console.log('CALL_NUMBER -> ' + companyData.companyPhone);
                            }} 
                            >{companyData.companyPhone}
                            </span>

                            

                        </div>

                        <div className="company-details__row">

                            <img src={icon_directions} alt="icon" width="48" height="48" />
                            <span style={{ cursor : 'pointer' }} onClick={() => { 
                                console.log('OPEN_IN_MAPS -> ' + companyData.locationStreet + ', ' + companyData.locationCity);
                            }} 
                            >{companyData.locationStreet}, {companyData.locationCity}
                            </span>

                        </div>

                        {companyData.companyWebsite ? (

                            <div className="company-details__row">

                                <img src={icon_web} alt="icon" width="48" height="48" />
                                <span style={{ cursor : 'pointer' }} onClick={() => { 
                                    console.log('OPEN_IN_BROWSER -> ' + companyData.companyWebsite);
                                }} 
                                >{strings.website}
                                </span>
                                
                            </div>

                        ) : (
                            <></>
                        )}

                        <div className="company-details__row">

                            <img src={icon_email} alt="icon" width="48" height="48" />
                            <span style={{ cursor : 'pointer' }} onClick={() => { 
                                console.log('SEND_EMAIL -> ' + companyData.companyEmail);
                            }} 
                            >{companyData.companyEmail}
                            </span>
                            
                        </div>

                        

                    </div>
                

                </div>       

            </div>
            
        )

    }
        



   
}

export default Company