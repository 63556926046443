import React from 'react'
import strings from '../language/strings';
import { Link } from "react-router-dom";

class CancelLink extends React.Component {

    render(){

       if( this.props.route && this.props.route !== '' ){
            return(
                <div className="cancel-content">
                    <Link to={this.props.route }>{ strings.cancel }</Link>
                </div>
                
            
            )
       }
       else{
           return '';
       }

    }
   
}

export default CancelLink