import { API_BASE } from "../utils/constants";
import strings from '../language/strings';


const api = {
    getAllCities,
    getCompaniesByCity,
    getCompany,
    getAllServiceTags,
};

    
async function getAllCities(){

    const request_url = API_BASE + 'get-eniro-cities';

    const response = await fetch(request_url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            language: determine_api_language(),
        }
        });
        
        if (response.status >= 200 && response.status <= 299) {
            return await response.json();
        } else {
            console.log(response.status, response.statusText);
        }

}

async function getCompaniesByCity( cityName ){

    const request_url = API_BASE + 'company/get-companies-thumbnails-by-city?cityName=' + cityName;

    const response = await fetch(request_url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            language: determine_api_language(),
        }
        });
        
        if (response.status >= 200 && response.status <= 299 ) {
            
            // handle possible empty response
            const res = await response.text();
            if( res.length === 0 ){
                return {};
            }
            else{
                return JSON.parse(res);
            }

        } else {
            console.log(response.status, response.statusText);
        }

}

async function getCompany( companyId ){
    

    const request_url = API_BASE + 'company/get-video-thumbnail-info?companyId=' + companyId;

    const response = await fetch(request_url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            language: determine_api_language(),
        }
        });

        if (response.status >= 200 && response.status <= 299) {
            return await response.json();
        } else {
            console.log(response.status, response.statusText);
            return false;
        }
        
    
}


async function getAllServiceTags( ){


    const request_url = API_BASE + 'company-tag-service/get-all-services';

    const response = await fetch(request_url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            language: determine_api_language(),
        }
        });

        if (response.status >= 200 && response.status <= 299) {
            return await response.json();
        } else {
            console.log(response.status, response.statusText);
        }
        
        
        

}

function determine_api_language(){

    if( strings.getLanguage() === 'sv' ){
        return 'SV';
    }else{
        return 'EN';
    }

}





export default api;