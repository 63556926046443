import React, { useState, useEffect } from 'react'
import strings from '../language/strings';

import api from "../functions/api";

import { Link, useParams } from "react-router-dom";
import Loader from '../components/Loader';
import BackLink from '../components/BackLink';

import default_thumb from '../assets/img/default_thumb.svg';
import svg_play from '../assets/img/play.svg';


function City() {

    const [companiesData, setCompaniesData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [allServiceTags, setAllServiceTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState([]);
    const [loading, setLoading] = useState (true);

    let { cityName } = useParams();


    useEffect(() => {
        // Code here will run just like componentDidMount

        window.last_city = cityName;

        Promise.all([api.getCompaniesByCity( cityName ), api.getAllServiceTags()])
        .then(([response, allServiceTagsResponse]) => {

            console.log(response);
            console.log(allServiceTagsResponse);

    
            setCompaniesData(response );
            setSuggestions(response );
            setAllServiceTags(allServiceTagsResponse);
            setLoading(false);

        });

      }, [cityName]);

    const setTagFilter = (e, tag) => {
        e.preventDefault();
        
        if( selectedTag === tag ){

            setSelectedTag('');

            setSuggestions(companiesData);

        }
        else{

            setSelectedTag(tag);

            let suggestions = [];

            companiesData.forEach(function(item, index){


               let itemService = item.companyTagServiceList.find(obj => {
                    return obj.tagServiceName === tag
                });

                if( itemService && itemService.hasService ){
                    suggestions.push( item );
                }


            });

            setSuggestions(suggestions);

        }

        

        

    }

    if( loading ){
        return(
         <div className="app-inner">
                <Loader />
         </div>
        )
    }
    else{

        
        return(
            <div className="app-inner consultation-form">
                    
                <div className="app-top">

                    <div className="app-header">
                        <div className="app-header__left">
                            <BackLink />
                        </div>
                        <div className="app-header__center">
                            <h2>{strings.city_header_prefix} {cityName}</h2>
                        </div>
                        <div className="app-header__right">
                        </div>
                    </div>

                    
                    <div className="service-tags-wrap">
                        <div className="service-tags">
                            {allServiceTags.map((tag) => (
                                <button key={tag} className={tag === selectedTag ? 'selected' : ''} onClick={ e => setTagFilter(e, tag) }>
                                    {tag}
                                </button>
                            ))}
                        </div>
                    </div>
                
                    
                    {suggestions.length ? (
                        <div className="companies-grid">
                        {suggestions.map((company) => (
                            <div className="companies-grid__item" key={company.companyId}>
                            <Link to={`/hairdresser/${company.companyId}`}>
                                {company.thumbnailUrl ? (
                                    <div className="HT companies-grid__thumb">
                                        <img src={company.thumbnailUrl} alt={company.companyName} className="thumb rounded"/>
                                        {company.videoUrl ? (
                                            <img src={svg_play} alt={strings.play} className="playbutton"/>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ) : (
                                    <div className="companies-grid__thumb">
                                        <img src={default_thumb} alt={company.companyName} className="thumb" />
                                    </div>
                                )}
                                <h3>{company.companyName}</h3>
                            </Link>
                            </div>
                        ))}
                        </div>
                    ) : (
                        <p>{strings.city_no_hits}</p>
                    )}

                </div>       

            </div>
            
        )

    }
        



   
}

export default City